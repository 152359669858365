export enum AccountTrackingAction {
  SignedIn = "signed_in",
  ConfirmResendVerificationEmail = "confirm_resend_verification_email",
  ClickBackToSignin = "click_back_to_signin",
  ConfirmSignIn = "confirm_sign_in",
  ConfirmSignUp = "confirm_sign_up",
  SignedUp = "signed_up",
  SubmittedUpdateAccountHolderForm = "submitted_update_account_holder_form",
  SignupError = "signup_error",
  AddToGoogleWalletError = "add_to_google_wallet_error",
  AccountClickedConnect = "clicked_connect",
  ClickedForgotPassword = "clicked_forgot_password",
  ClickedDeletePassenger = "deleted_passenger",
  ClickedAddPassenger = "clicked_add_passenger",
  ClickedNewSearch = "clicked_new_search",
  AddedPassengerProfile = "added_passenger_profile",
  UpdatedPassengerProfile = "updated_passenger_profile",
  ConfirmAccountUpdate = "confirm_account_update",
  RemovedPassengerProfile = "removed_passenger_profile",
  ClickedPurchase = "clicked_purchase",
  ClickedCancelUserPurchase = "clicked_cancel_purchase",
  ClickedConfirmCancellation = "confirmed_cancel_purchase",
  ClickedResendEmailVerification = "clicked_resend_email_verification",
  ClickedConfirmNewPassword = "confirm_new_password",
  ClickedDeleteCreditCard = "clicked_delete_credit_card",
  RemovedCreditCard = "removed_credit_card",
  ClickedUpdateCreditCard = "clicked_update_credit_card",
  UpdatedCreditCard = "updated_credit_card",
  ClickedDeleteBillingAddress = "clicked_delete_billing_address",
  UpdatedPassword = "updated_password",
  ClickedUpdatePassword = "clicked_update_password",
  UpdatedUser = "updated_user",
  UpdatedBillingAddress = "updated_billing_address",
  ClickedUpdateBillingAddress = "clicked_update_billing_address",
  DeletedBillingAddress = "deleted_billing_address",
  ClickedUpdateEmailAddress = "clicked_update_email_address",
  UpdatedEmailAddress = "updated_email_address",
  ClickedAddBillingAddress = "clicked_add_billing_address",
  ClickedEmailOptInCheckboxSignUp = "clicked_email_opt_in_checkbox", // Duplicate of ClickedEmailOptInCheckbox
  ClickedDownloadTicketsTabs = "clicked_download_tickets_tabs",
  ClickedDownloadTickets = "clicked_download_tickets"
}
export enum CheckoutTrackingAction {
  StartedCheckout = "started_checkout",
  EnteredCheckoutPayment = "entered_checkout_payment",
  ClickedCheckoutStep = "clicked_checkout_step",
  FailedCheckoutPassengerForm = "failed_checkout_passenger_form",
  ConfirmedCheckout = "confirmed_checkout",
  Redirect3DS = "redirect_3ds",
  FailedCheckoutForm = "failed_checkout_form",
  CheckoutErrorPopup = "checkout_error_popup",
  CompletedOrder = "completed_order",
  FailedOrder = "failed_order",
  ClickedUpdatePrice = "clicked_update_price",
  CancelledPriceUpdate = "cancelled_price_update",
  ClickedUpdateCurrency = "clicked_update_currency",
  OpenedInstallmentsField = "opened_installments_field",
  UpdatedInstallmentsQuantity = "updated_installments_quantity",
  ClickedRedeemCredits = "clicked_redeem_credits",
  RedeemCreditsError = "redeem_credits_error",
  UnredeemCreditsError = "unredeem_credits_error",
  ClickedUnredeemCredits = "clicked_unredeem_credits",
  ValidatedDiscountCode = "validated_discount_code",
  DiscountCodeError = "discount_code_error",
  OpenedCountryPicker = "opened_country_picker",
  PickedCountryInPicker = "picked_country_in_picker",
  ClickedEmailOptInCheckbox = "clicked_email_opt_in_checkbox",
  ExitedCheckoutSeats = "exited_checkout_seats",
  OpenedCheckoutSeats = "opened_checkout_seats",
  OpenedRefundProtectDetails = "opened_refund_protect_details",
  ClickedCheckoutSeat = "clicked_checkout_seat",
  ClickedCreateAccountToggle = "clicked_create_account_toggle",
  SelectedAncillary = "selected_ancillary",
  UnselectedAncillary = "unselected_ancillary",
  SelectedSavedTraveller = "selected_saved_traveller",
  NonAlphabeticNameWarningTriggered = "non_alphabetic_name_warning_triggered",
  ClickedEmailSuggestion = "clicked_email_suggestion",
  SavePassengerQuestionAnswersCheckboxToggled = "save_passenger_question_answers_checkbox_toggled",

  /** Referral Cart */
  ClickedReferralCartSelect = "clicked_referral_cart_select"
}
export enum ResultsTrackingAction {
  CardCollapsed = "card_collapsed",
  CardExpanded = "card_expanded",
  CartCreationError = "cart_creation_error",
  CartCreationPriceChanged = "cart_creation_price_changed",
  CartCreationPriceChangedAccepted = "cart_creation_price_changed_accepted",
  CartCreationPriceChangedRefused = "cart_creation_price_changed_refused",
  CartCreationStarted = "cart_creation_started",
  CartCreationSucceed = "cart_creation_succeed",
  ClickedAlternativeRoute = "clicked_alternative_route",
  ClickedCancelWheelchairSearch = "clicked_cancel_wheelchair_search",
  ClosedBusDetails = "closed_bus_details",
  DisruptionClosed = "disruption_closed",
  DisruptionDisplayed = "disruption_displayed",
  DisruptionLinkClicked = "disruption_link_clicked",
  DownloadMobileAppModalAppIconClicked = "download_mobile_add_modal_app_icon_clicked",
  DownloadMobileAppModalDisplayed = "download_mobile_add_modal_displayed",
  ErrorAdBanner = "error_ad_banner",

  LoadedBusDetails = "loaded_bus_details",
  LoadedBusDetailsFailed = "loaded_bus_details_failed",
  LoadedInterconnections = "loaded_interconnections",
  LoadedResults = "loaded_results",
  OpenedBusDetails = "opened_bus_details",
  PickedGenderBusDetails = "picked_gender_bus_details",
  SelectDateTab = "select_date_tab",
  SelectedDeparture = "selected_departure",
  ShowedTtlError = "showed_ttl_error",
  SortedResults = "sorted_results",
  SuspendedOperationsAlertClosed = "suspended_operations_alert_closed",
  SuspendedOperationsAlertDisplayed = "suspended_operations_alert_displayed",
  SuspendedOperationsAlertLinkClicked = "suspended_operations_alert_link_clicked",
  ToggleFilters = "toggle_filters",
  TripReconciliationFailed = "trip_reconciliation_failed",
  ViewAsyncCart = "checkout_pageview",

  ViewSeatSelection = "seat_selection_pageview",
  ViewTicketOptions = "ticket_options_pageview",
  VotedPriceLockSmokeTest = "price_lock_smoke_test",

  /** Asyn-Cart */
  CartUpdateStarted = "cart_updated_started",
  CartUpdateSucceed = "cart_updated_succeed",
  CartUpdateError = "cart_updated_error"
}
export enum SearchTrackingAction {
  /** Search */
  ClickedSearchReturnDate = "clicked_search_return_date",
  ClickedReverseCities = "clicked_reverse_cities",
  ClickedAffiliateCheckbox = "clicked_tab_under_checkbox",
  ClickedSearchOrigin = "clicked_search_origin",
  ClickedSearchDestination = "clicked_search_destination",
  OpenedSearchDestination = "opened_search_destination",
  ClickedSearchOutboundDate = "clicked_search_outbound_date",
  UnselectedReturnDate = "unselect_return_date",
  ClickedSearchPassenger = "clicked_search_passenger",
  SearchedRoute = "searched_route",
  FailedRouteSearch = "failed_route_search",
  SelectedOrigin = "selected_origin",
  SelectedDestination = "selected_destination",
  InvalidCityError = "invalid_city_error",
  SelectedDepartureDate = "selected_departure_date",
  SelectedReturnDate = "selected_return_date",
  ToggledSearchVisibility = "toggled_search_visibility"
}
export enum TrackingAction {
  /** Authentication */
  ClickMenu = "click_menu",

  /** Drawer */
  OpenItineraryDrawer = "open_itinerary_drawer",
  CloseItineraryDrawer = "close_itinerary_drawer",
  CloseOptionsDrawer = "close_options_drawer",
  OpenResultsFiltersDrawer = "open_results_filters_drawer",
  CloseResultsFiltersDrawer = "close_results_filters_drawer",

  /** Error */
  FailedRequest = "failed_request",
  ClickedErrorButton = "clicked_error_button",

  /** Header */
  ClickedMenu = "clicked_menu",
  ClickedMenuTickets = "clicked_menu_tickets",
  ClickedMenuProfile = "clicked_menu_profile",
  ClickedMenuLogout = "clicked_menu_logout",
  HeaderClickedConnect = "clicked_connect",

  /** Interstitial */
  InternalRedirect = "internal_redirect",
  ExternalRedirect = "external_redirect",

  /** Itinerary */
  ExpandStops = "expand_stops_itinerary_drawer",
  CollapseStops = "collapse_stops_itinerary_drawer",
  SwitchItineraryTabs = "switch_tab_itinerary_drawer",
  ShowedReturnItinerary = "show_itinerary_return",
  ClickedStopDetailsLink = "clicked_stop_details_link",
  ClickedStopMapLink = "clicked_stop_map_link",

  /** Link */
  ClickLink = "click_link",

  /** Price Calendar */
  ClickedRouteCalendarTab = "clicked_route_calendar_tab",
  ClickedRouteCalendarMore = "clicked_route_calendar_more",
  ClickedRouteCalendarDeparture = "clicked_route_calendar_departure",

  /** Purchase confirmation */
  ClickedDownloadNativeApp = "clicked_download_native_app",
  ClickedSeeMoreButton = "clicked_see_more_button",
  ClickResendConfirmationEmail = "click_resend_confirmation_email",
  ClosedAccessTicketsPopup = "closed_access_tickets_popup",
  FailResendConfirmationEmail = "fail_resend_confirmation_email",
  RoundtripLinkClicked = "clicked_roundtrip_link",
  SucceedResendConfirmationEmail = "succeed_resend_confirmation_email",

  /** Purchase */
  RatedApplication = "rated_application",

  /** Quick Access Menu */
  ClickedQuickAccessMenuLink = "clicked_quick_access_menu_link",

  /** Reviews */
  ClickedReadMoreReviews = "clicked_read_more_reviews",
  ClickedReadLessReviews = "clicked_read_less_reviews",

  /** Shared across multiple pages */
  AffiliateRedirected = "affiliate_redirected",
  ClickAdBanner = "clicked_ad_banner",
  ViewedAdBanner = "viewed_ad_banner",
  LoadedAdBanner = "loaded_ad_banner",

  /** Train Tickets Page */
  SearchedFromTrainTicketsPage = "searched_from_train_tickets_page",

  ViewModal = "view_modal"
}

export type AllTrackingAction =
  | TrackingAction
  | SearchTrackingAction
  | AccountTrackingAction
  | ResultsTrackingAction
  | CheckoutTrackingAction;
