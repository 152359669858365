import React, { createContext } from "react";

import { BBType } from "@app/types/bb-types";

import type {
  DynamicWhitelabel,
  Partner,
  PartnerConfig,
  Whitelabel
} from "@partners/partner.type";

interface LegacyWhitelabel {
  name: Whitelabel;
  partner_legacy_key: Partner;
}

export type UnifiedWhitelabel = LegacyWhitelabel | DynamicWhitelabel;

export interface WhitelabelContext {
  whitelabel: UnifiedWhitelabel | null;
  legacy_partner_settings: PartnerConfig["whitelabel_settings"] | null;
}

export const WhitelabelContext = createContext<WhitelabelContext>({
  whitelabel: null,
  legacy_partner_settings: null
});

export const WhitelabelContextProvider = WhitelabelContext.Provider;

export const useWhitelabelContext = () => {
  const context = React.useContext(WhitelabelContext);
  if (context === undefined) {
    throw new Error(
      "useWhitelabelContext must be used within a WhitelabelContextProvider"
    );
  }

  return context;
};

export const isDynamicWhiteLabel = (
  whitelabel: UnifiedWhitelabel | null | undefined
): whitelabel is DynamicWhitelabel => {
  return !!whitelabel && "deal_type" in whitelabel;
};

export const useIsPoweredByWhitelabel = (): boolean => {
  const { whitelabel } = useWhitelabelContext();
  return (
    isDynamicWhiteLabel(whitelabel) &&
    whitelabel.deal_type === "powered_by_whitelabel"
  );
};

export const WhitelabelClientContextProvider: React.FC<{
  BB: Pick<BBType, "whitelabel_context">;
  children: React.ReactNode;
}> = ({ BB, children }) => {
  const { whitelabel_context } = BB;

  if (!whitelabel_context) {
    return <>{children}</>;
  }

  return (
    <WhitelabelContextProvider value={whitelabel_context}>
      {children}
    </WhitelabelContextProvider>
  );
};
